





















































import _ from "lodash";
import rest from "@/rest";
import router from "@/router";
import { Component, Vue, Prop } from "vue-property-decorator";
import { vuex } from "@/store";
import { namespace } from "vuex-class";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// interfaces
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { CustomizationVm } from "@/interfaces/CustomizationVm";

const auth = namespace("auth");
const customization = namespace("customization");

@Component({
  components: {
  }
})
export default class CustomerSettingsView extends Vue {
  busy = false;
  @auth.Getter isAdmin: any;
  // @customization.Action loadCustomizationSettings!: any;
  @customization.Action saveCustomizationSettings!: any;
  @customization.Getter customizationSettings!: CustomizationVm;

  editSettings: CustomizationVm | null = null;

  // $refs!: {
  // }

  async mounted() {
    if (!this.isAdmin)
      router.push("/");

    console.log("App name", this.customizationSettings?.appName);

    this.editSettings = _.cloneDeep(this.customizationSettings);
  }

  created() {
    // Disable body scroll on iOS
    this.$nextTick(async () => {
      await this.$globalHelper.delay(10);
      const modal = document.querySelector('.modal');
      disableBodyScroll(modal);
    });
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  onSave() {
    this.busy = true;
    try {
      this.saveCustomizationSettings(this.editSettings);
    } finally {
      this.busy = false;
    }
  }

  get containerPadding() {
    if (this.$vuetify.breakpoint.xs)
      return "padding: 0px;";
    return "";
  }

  get lightTransparentColor() {
      return this.$store.state.ux.colorAccent5 + "40";
  }

  goHome() {
    if (router.currentRoute.path == "/")
      return;
    router.push("/");
  }
}
