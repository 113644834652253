

































  import _ from "lodash";
  import rest from "@/rest";
  // import wretch, { WretcherError } from "wretch";
  import { Component, Vue } from "vue-property-decorator";
  import { List } from "linq-collections";
  import { signalrEventBus } from "@/main";

  // Components
  import TusFileDrop from "@/components/Shared/TusFileDrop.vue";
  import EditScormDetailsDlg from "@/components/ExerciseManager/EditScormDetailsDlg.vue";
  import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode
  import { namespace } from "vuex-class";

  // Interfaces
  import { ParamDictionary } from "@/interfaces/ParamDictionary";
  import { ExBundleGroupVM, ExBundleVM } from '../interfaces/ExBundleGroupVM';
  import { ExCategory1 } from "@/interfaces/ExCategory1";
  import { ExCategory2 } from "@/interfaces/ExCategory2";
  import { CategorySelectListsVm } from "@/interfaces/CategorySelectListsVm";

  const auth = namespace("auth");
  const ux = namespace("ux");

  @Component({
    components: {
      TusFileDrop,
      EditScormDetailsDlg,
      qrcode: VueQrcode
    }
  })
  export default class ContentCreatorView extends Vue {
    $refs!: {
      detailsPanel: HTMLFormElement;
      qrcodeCanvas: HTMLCanvasElement;
    };
    @auth.Getter isAdmin: any;

    showDeleteFileDialog: boolean = false;
    paramDir: ParamDictionary = { dictionary: {}};
    category1Items: ExCategory1[] = [];
    selectedCat1Item: ExCategory1 | null = null;
    category2Items: ExCategory2[] = [];
    selectedCat2Item: ExCategory2 | null = null;

    newGuid: string | null = null;

    async mounted() {
      await this.loadCategories("cat1", "");
    }

    created() {
      // SignalR event listener
      // signalrEventBus.$on('zipArchiveCreationFinished', this.zipArchiveCreationFinished);
    }

    beforeDestroy() {
      // clearAllBodyScrollLocks();
      // Make sure to cleanup SignalR event handlers when removing the component
      // signalrEventBus.$off('zipArchiveCreationFinished', this.zipArchiveCreationFinished);
    }

    updateView() {
    }

    // SignalR events
    // zipArchiveCreationFinished(response: any) {
    //   console.log("importFinished", response.zipFileName + " " + response.message);
    //   if (response.status == "SUC") {
    //     this.$globalHelper.download(`api/resource/Temp/${response.zipFileName}`, response.zipFileName);
    //   } else {
    //   }

    //   // this.$emit('content:ZipArchiveCreationFinished');
    //   this.ziArchiveCreationBusy = false;
    //   this.bundleZipCreationBusy = false;
    //   this.scormZipCreationBusy = false;
    // }

    async cat1Changed(cat1: ExCategory1) {
      this.selectedCat1Item = cat1;
      await this.loadCategories("cat1", "");
    }

    async cat2Changed(cat2: ExCategory2) {
      this.selectedCat2Item = cat2;
      await this.loadCategories("cat2", "");
    }

    async loadCategories(changeEvent: string, selectGuid: string): Promise<void> {
       if (this.paramDir == null || this.paramDir.dictionary == null)
        return;

      this.paramDir.dictionary["ChangeEvent"] = changeEvent;
      this.paramDir.dictionary["SelectGuid"] = selectGuid;
      // this.paramDir.dictionary["UserId"] = this.selectedUser.id!;
      // this.paramDir.dictionary["GroupId"] = this.selectedGroup.id.toString()!;
      this.paramDir.dictionary["Category1Id"] = this.selectedCat1Item?.id.toString() ?? "0";
      this.paramDir.dictionary["Category2Id"] = this.selectedCat2Item?.id.toString() ?? "0";
      this.paramDir.dictionary["Category3Id"] = "0";

      await rest.url("exercises/loadAllCategories").post(this.paramDir)
      .then((result: CategorySelectListsVm) => {
        this.category1Items = result.category1Items!;
        this.selectedCat1Item = result.selectedCat1Item
        this.category2Items = result.category2Items!;
        this.selectedCat2Item = result.selectedCat2Item
        // this.category3Items = result.category3Items!;
        // this.selectedCat3Item = result.selectedCat3Item
      })
    }

    get selectedCat1ItemName() {
      if (!this.selectedCat1Item)
        return "-";
      return this.selectedCat1Item.name;
    }

    get selectedCat2ItemName() {
      if (!this.selectedCat2Item)
        return "-";
      return this.selectedCat2Item.name;
    }

    async generateGuid() {
      this.newGuid = await rest.url("contentManager/getNewGuid").get();
      // this.showGuidDialog = true;
    }

    // replace with vuex variant
    get backgroundColor() {
      let currentThemeName = this.$vuetify.theme.dark ? 'dark' : 'light';
      return this.$vuetify.theme.themes[currentThemeName].background;
    }
  }
