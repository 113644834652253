














































































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List } from "linq-collections";
import { vuex } from "@/store";
import moment, { duration } from "moment";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// component
import AddUsers from "@/components/UserManagement/AddUsers.vue";

// interfaces
import { ExBundleVM } from "@/interfaces/ExBundleVM";
import { BundleApprovalVm } from "@/interfaces/BundleApprovalVm";
import { ParamAddUsersVm } from "@/interfaces/ParamAddUsersVm";
import { PortalUserVM } from "@/interfaces/PortalUserVM";

//const auth = namespace("auth");
// const globals = namespace("globals");

@Component({
  components: {
    AddUsers
  }
})
export default class ReviewProcessDlg extends Vue {
  @PropSync("showDialog") syncedShowDialog!: boolean;
  @Prop() bundle!: ExBundleVM;

  busyOnSave = false;
  hasDueDate: boolean = false;
  showDueDateDlg: boolean = false;
  showDueDateTimeDlg: boolean = false;
  showAddUserDialog = false;

  editBundle: ExBundleVM | null = null;
  // approvers: BundleApprovalVm[] | null = null;
  assignmentDate: string | null = null;
  dueDate: string | null = null;
  dueDateTime: string | null = null;

  // @auth.Getter isAdmin: any;
  // @auth.Getter isOrgAdmin: any;
  // @auth.Getter isGroupAdmin: any;

  mounted() {
    vuex.globals.setGroup(null); // Make sure to show all users in AddUsers dialog
  }

  @Watch("syncedShowDialog")
  async onShowDialogChanged(val: boolean) {
    if (this.syncedShowDialog) {
      // On dialog open
      this.busyOnSave = false;
      await this.updateView();
      this.editBundle = _.cloneDeep(this.bundle);
      this.hasDueDate = this.bundle.bundleReviewDueDate != null;
      if (this.hasDueDate) {
        // this.dueDateTime = moment(this.bundle.bundleReviewDueDate).format("HH:mm"); // HH => 24h format
        this.dueDate = moment(this.bundle.bundleReviewDueDate).format("yyyy-MM-DD");
      } else {
        this.dueDate = this.minDate;
      }

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      clearAllBodyScrollLocks();
    }
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  async updateView() {
    // this.getApprovers();
  }

  // async getApprovers() {
  //   await rest.url("contentManager/getApprovers").query({ bundleId: this.bundle.id }).get()
  //   .then((response) => {
  //     this.approvers = response;
  //   })
  // }

  // onAddUser() {
  //   this.showAddUserDialog = true;
  // }

  // addUsers(newUsers: PortalUserVM[]) {
  //   if (!this.approvers)
  //     return;

  //   let currentUserIds =  new List(this.approvers).select(x => x.userId);

  //   let bundleApprovalVms = new List(newUsers)
  //     .where(x => !currentUserIds.contains(x.id))
  //     .select(x => <BundleApprovalVm>{ approved: false, avatar: x.avatar, comment: "", exBundleId: this.bundle.id, deleted: false, fullName: x.fullName, isOwner: false, userId: x.id }).toArray();
  //   this.approvers.push(...bundleApprovalVms);
  // }

  // async onRemoveApprover(approver: BundleApprovalVm) {
  //   if (!this.approvers)
  //     return;

  //   this.approvers = this.approvers.filter(x => x != approver);
  // }

  async updateBundle() {
    if (!this.editBundle)
      return;

    this.busyOnSave = true;

    // update reviewers
    // let params = <ParamAddUsersVm>{ groupId: 0, orgId: 0, bundleId: this.bundle.id, userIds: new List(this.approvers).select(u => u.userId).toArray() };
    // await rest.url("contentManager/updateApprovers")
    //   .post(params)
    //   .finally(() => {
    //     this.busyOnSave = false;
    //   });

    if (!this.hasDueDate) {
      this.editBundle.bundleReviewDueDate = null;
    } else {
      this.editBundle.bundleReviewDueDate = moment(this.dueDate).toDate();
    }

    // update bundle
    this.busyOnSave = true;
    await rest.url("contentManager/updateBundle")
      .post(this.editBundle)
      .then((response) => {
        this.$emit("updateView:ReviewProcessDlg");
        this.syncedShowDialog = false;
      })
      .finally(() => {
        this.busyOnSave = false;
      });
  }

  get bundleId() {
    if (!this.bundle)
      return 0;
    return this.bundle.id;
  }

  get minDate() {
    return new Date().toISOString().substring(0, 10);
  }

  // approvalDateToLocalDate(approval: BundleApprovalVm): string {
  //   if (!approval?.date)
  //     return "-";

  //   // let date = moment(item?.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
  //   let date = moment(approval.date).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
  //   return date;
  // }

  bundleReviewDateToLocalDate(bundle: ExBundleVM): string {
    if (!bundle?.bundleReviewedByDate)
      return "";

    // let date = moment(item.bundleReviewedByDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let date = moment(bundle.bundleReviewedByDate).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return date;
  }


  get dueDateToLocalDate() {
    if (!this.dueDate)
      return "-";

    let date = moment(this.dueDate).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return date;
  }

  // get dueDateFormated() {
  //   return this.formatDate(this.dueDate);
  // }

  // formatDate(date) {
  //   if (date == null)
  //     return "-";

  //   return moment(date).format("DD.MM.yyyy");
  // }

  formatCalendar(date) {
    if (date == null)
      return "-";

    let mDate = moment(date);
    if (mDate.date() == moment().date())
      return ("sofort");

    return mDate.calendar();
  }

  onCancel() {
    clearAllBodyScrollLocks();
    this.syncedShowDialog = false;
  }
}
