















































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { PortalUserVM, SelectListItem } from "@/interfaces/PortalUserVM";
import { PortalUser } from "@/interfaces/PortalUser";
import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode
import de from "vuetify/src/locale/de";
import { GroupExtVm } from "@/interfaces/GroupExtVm";

const globals = namespace("globals");

@Component({
  components: {
    qrcode: VueQrcode
  }
})
export default class UserQrCodeCard extends Vue {
  @Prop() user!: PortalUserVM;

  @globals.Getter selectedGroup!: GroupExtVm;

  // $refs!: {};

  mounted() {}
}
