



































































































import { ParamDictionary } from "@/interfaces/ParamDictionary";
import rest from "@/rest";
import router from "@/router";
import { Component, Vue, Prop } from "vue-property-decorator";
import { vuex } from "@/store";
import { namespace } from "vuex-class";
// interfaces
import { CustomizationVm } from "@/interfaces/CustomizationVm";

const customization = namespace("customization");

@Component({
  components: {
  }
})
export default class Account extends Vue {
  @customization.Getter customizationSettings!: CustomizationVm;
  resetToken: string | null = null;
  emailAddress: string | null = null;
  pwdValid = false;
  password1: string = "";
  password2: string = "";
  showPassword: boolean = false;

  $refs!: {
    pwdForm: HTMLFormElement;
  }

  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.resetToken = urlParams.get("token");
    this.emailAddress = urlParams.get("email");
  }

  async onResetPassword() {
    if (!this.$refs.pwdForm.validate()) return;
    // if (this.params == null || this.params.dictionary == null)
    //   return;
    if (!this.resetToken || !this.emailAddress) return;

    let params: ParamDictionary = { dictionary: {} };
    params.dictionary!["Token"] = this.resetToken;
    params.dictionary!["Email"] = this.emailAddress;
    params.dictionary!["NewPassword"] = this.password1;

    await rest
      .url("auth/resetPassword")
      .post(params)
      .then((response) => {
        //console.log("response: ", response);
        if (response == true) {
          vuex.ux.SB_SUCCESS({
            message: "Neues Passwort wurde erfolgreich gesetzt",
            timeout: 10000
          });
        }

        if (this.$refs.pwdForm != undefined) this.$refs.pwdForm.reset();
        this.goHome();
      })
      .finally(() => {
      });
  }

  get passwordConfirmed() {
    if (!this.password1)
      return "";
    if (!this.password2)
      return "Passwort erforderlich";
    if (this.password1 == this.password2)
      return "";
    return "Passwörter sind unterschiedlich";
  }

  newPasswordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => {
      if (!v)
        return "Passwort erforderlich";

      let matches = 0;

      if (v.length < 8) return "Passwort muss mindestens 8 Zeichen lang sein";
      if (v.length > 32) return "Passwort ist zu lang";

      let hasDigit = /.*\d.*/;
      let hasLowerCase = /.*[a-z].*/;
      let hasUpperCase = /.*[A-Z].*/;
      let hasSpecialChar = /.*[*.!@#$%^&§(){}\[\]\\:;<>,.?/~_+\-=|'].*/;

      if( hasDigit.test(v) )
        matches ++;
      if( hasLowerCase.test(v) )
        matches ++;
      if( hasUpperCase.test(v) )
        matches ++;
      if( hasSpecialChar.test(v) )
        matches ++;

      if (matches >= 3)
        return true;

      if (matches == 1)
        return `Es ist nur ${matches} von mindestens 3 Kriterien erfüllt. [Kleinbuchstaben, Großbuchstaben, Zahl, Sonderzeichen]`;
      return `Es sind nur ${matches} von mindestens 3 Kriterien erfüllt. [Kleinbuchstaben, Großbuchstaben, Zahl, Sonderzeichen]`;
    }
  ];

  get containerPadding() {
    if (this.$vuetify.breakpoint.xs)
      return "padding: 0px;";
    return "";
  }

  goHome() {
    if (router.currentRoute.path == "/")
      return;
    router.push("/");
  }
}
